// This module has no other explicit imports/exports, so this ensures TypeScript understands this
// is a module-type file and not a global-script-type file, so that global declaration side effects
// propagate.
export {};

type ModalCloseRequestedEvent = CustomEvent<{
  element: HTMLElement;
}>;

type ModalShowRequestedEvent = CustomEvent<{
  element: HTMLElement;
}>;

declare global {
  interface WindowEventMap {
    'modal-close-requested': ModalCloseRequestedEvent;
    'modal-show-requested': ModalShowRequestedEvent;

    /**
     * Fired when a user clicks on the shroud.
     */
    'shroud-clicked': Event;
  }
}

/**
 * Make all the Shopify sections (including the header and the footer, but excluding a section
 * containing a modal dialog) inert. Disable background scrolling and hide the Attentive modal
 * window if any is active.
 *
 * This is a relatively safe and widely supported method of disabling the active background elements
 * when displaying modal windows. The active elements have to be disabled to avoid a11y problems
 * with keyboard browsing.
 */
function onModalShowRequested(event: ModalShowRequestedEvent) {
  const modal = event.detail.element;
  const modalSection = globalClosest(modal, '.shopify-section');

  document.body.addEventListener('touchmove', onTouchMove);

  showShroud();

  // Make other sections inert unless the sections are flagged as always active.

  const sections = document.querySelectorAll<HTMLElement>('.shopify-section');
  for (const section of sections) {
    if (section !== modalSection && !section.classList.contains('a11y-always-active')) {
      section.setAttribute('inert', '');
    }
  }

  hideAttentiveModal();
}

function hideAttentiveModal() {
  if (window.__attentive?.invoked && __attentive.hide) {
    __attentive.hide();
  }
}

/**
 * Clear the inert attribute for all Shopify sections, enable the background scrolling and show the
 * Attentive dialog if it was active before.
 */
function onModalCloseRequested(_event: ModalCloseRequestedEvent) {
  const sections = document.querySelectorAll('.shopify-section');
  for (const section of sections) {
    section.removeAttribute('inert');
  }

  document.body.removeEventListener('touchmove', onTouchMove);

  hideShroud();

  showAttentiveModal();
}

function showAttentiveModal() {
  if (window.__attentive?.invoked && __attentive.show) {
    __attentive.show();
  }
}

function onTouchMove(event: TouchEvent) {
  event.preventDefault();
}

/**
 * Works like `Element.closest()` method but crosses the ShadowRoot boundaries
 */
function globalClosest(element: Element, selector: string) {
  if (!element) {
    return;
  }

  const closest = element.closest(selector);

  if (closest) {
    return closest;
  }

  const root = element.getRootNode();
  // under some circumstances `getRootNode()` may return the document, the element itself etc
  // we are only interested in cases where the root is a custom element's ShadowRoot
  if (isShadowRoot(root)) {
    return globalClosest(root.host, selector);
  }
}

function isShadowRoot(node: Node): node is ShadowRoot {
  return 'host' in node;
}

/**
 * This function supports calls to show the shroud even if it is already shown.
 */
function showShroud() {
  const shroud = document.getElementById('modal-shroud');

  // just in case of a bug/inconsistency in calling this function we clean up any previous listeners
  shroud.removeEventListener('click', onShroudClick);
  document.removeEventListener('keyup', onShroudClick);

  shroud.addEventListener('click', onShroudClick);
  document.addEventListener('keyup', onShroudClick);

  document.documentElement.classList.add('js-no-scroll');

  shroud.classList.add('active');
}

function onShroudClick(event: KeyboardEvent | MouseEvent) {
  if (event instanceof KeyboardEvent) {
    if (event.code !== 'Escape') {
      return;
    }
  }

  dispatchEvent(new Event('shroud-clicked'));
}

/**
 * This function supports calls to hide the shroud even if it is already hidden.
 */
function hideShroud() {
  const shroud = document.getElementById('modal-shroud');
  shroud.removeEventListener('click', onShroudClick);
  document.removeEventListener('keyup', onShroudClick);

  document.documentElement.classList.remove('js-no-scroll');

  shroud.classList.remove('active');
}

addEventListener('modal-show-requested', onModalShowRequested);
addEventListener('modal-close-requested', onModalCloseRequested);
